up.compiler("select option[value='']", (element) => {
  element.classList.add("text-gray-400", "italic");
});

up.compiler("li > input.search-dropdown", (element) => {
  var list = element.parentElement.nextElementSibling.querySelectorAll(
    ".dropdown-select-item"
  );
  element.addEventListener("input", function () {
    var value = this.value.toLowerCase();
    list.forEach((item) => {
      item.style.display = item.textContent.toLowerCase().includes(value)
        ? "flex"
        : "none";
    });
  });
});

up.compiler("input[up-reload]", (element) => {
  var target = element.getAttribute("up-reload").split(":");
  const condition = target[1];
  target = target[0];

  if (condition != undefined) {
    element.addEventListener("change", (evt) => {
      if (condition == "check" && evt.target.checked) {
        upreload(target);
      } else if (condition == "uncheck" && !evt.target.checked) {
        upreload(target);
      }
    });
  } else {
    element.addEventListener("click", () => {
      upreload(target);
    });
  }
});

function upreload(target) {
  up.reload({'up-target': target})
}
